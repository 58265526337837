import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 rounded-md bg-hub-background-3 p-2 flex" }
const _hoisted_2 = ["href"]

import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { M_FileMessageEventContent } from '@/types/events';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFile',
  props: {
    message: {}
  },
  setup(__props: any) {

	const { formUrlfromMxc } = useMatrixFiles();

	const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, {
      type: "paperclip",
      class: "mr-2"
    }),
    _createElementVNode("a", {
      class: "text-blue",
      target: "_blank",
      href: _unref(formUrlfromMxc)(_ctx.message.url)
    }, _toDisplayString(_ctx.message.filename), 9, _hoisted_2)
  ]))
}
}

})
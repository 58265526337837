import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-44 w-full" }
const _hoisted_2 = { class: "rounded-b-md bg-white-middle text-hub-text dark:bg-gray" }
const _hoisted_3 = { class: "w-fit flex -mt-6 py-1 px-4 rounded-r-md bg-blue-light" }
const _hoisted_4 = { class: "pl-2 text-white" }
const _hoisted_5 = { class: "flex flex-col px-4 pb-1 mt-1" }
const _hoisted_6 = { class: "flex flex-row justify-between gap-x-8 pb-1" }
const _hoisted_7 = { class: "font-normal text-xs" }
const _hoisted_8 = { class: "bg-blue-light text-white rounded-sm self-end focus:outline-none focus:ring-2 focus:ring-opacity-75 hover:bg-blue" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleBlock',
  props: {
		image: {
			type: String,
			default: 'img/imageplaceholder.jpg',
		},
		to: {
			type: Object,
			default: null,
		},
	},
  setup(__props) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_ImagePlaceholder = _resolveComponent("ImagePlaceholder")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_H3 = _resolveComponent("H3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ImagePlaceholder, {
      src: __props.image,
      class: "rounded-t-md overflow-hidden"
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          type: "speech_bubbles",
          size: "sm",
          class: "text-black"
        }),
        _createElementVNode("p", _hoisted_4, [
          _renderSlot(_ctx.$slots, "category")
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_H3, { class: "font-semibold text-base" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _: 3
        }),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, [
            _renderSlot(_ctx.$slots, "content")
          ]),
          _createElementVNode("button", _hoisted_8, [
            _createVNode(_component_Icon, { type: "arrow-right" })
          ])
        ])
      ])
    ])
  ]))
}
}

})
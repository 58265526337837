import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex gap-4 items-center" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 1,
  class: "text-base z-0"
}
const _hoisted_4 = { class: "grid gap-2 items-center" }

import { router } from '@/core/router';
	import { ref } from 'vue';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useI18n } from 'vue-i18n';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomPill',
  props: {
		room: Object,
		roomIsSecure: Boolean,
	},
  setup(__props) {

	const pubhubs = usePubHubs();
	const { t } = useI18n();

	const expanded = ref(false);
	const joinedARoom = ref(false);

	const props = __props;

	function expandPillToggle() {
		expanded.value = !expanded.value;
	}

	async function joinRoom() {
		if (props.room?.room_id) {
			if (props.roomIsSecure === true) {
				router.push({ name: 'secure-room', params: { id: props.room.room_id } });
			} else {
				joinedARoom.value = true;
				setTimeout(() => {
					pubhubs.joinRoom(props.room?.room_id);
				}, 3000);
			}
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_H3 = _resolveComponent("H3")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex max-w-[23rem] min-w-56 h-min rounded-md bg-white-middle text-black dark:bg-gray dark:text-white overflow-hidden cursor-pointer relative",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (expandPillToggle()))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 top-0 h-full w-3 shrink-0 bg-hub-background-5 transition-all duration-1000 ease-in-out", { 'bg-notification w-full': joinedARoom.value }])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between p-2 pl-5 py-3 gap-2 w-full", { 'gap-4': expanded.value }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: __props.roomIsSecure ? 'shield' : 'speech_bubbles',
          class: "shrink-0"
        }, null, 8, ["type"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_H3, {
            class: _normalizeClass(["font-semibold overflow-hidden m-0 line-clamp-1 z-0", { 'line-clamp-3': expanded.value && !joinedARoom.value }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.room?.name), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          (joinedARoom.value === false)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(["text-xs line-clamp-1 italic", { 'line-clamp-3': expanded.value }])
              }, _toDisplayString(__props.room?.topic), 3))
            : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)('rooms.joined')), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icon, {
          type: "join_room",
          size: "lg",
          class: "hover:cursor-pointer hover:opacity-80",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (joinRoom()))
        })
      ])
    ], 2)
  ]))
}
}

})
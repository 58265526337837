import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4 whitespace-pre-line" }

import Room from '@/pages/Room.vue';
	import { buttonsYesNo, DialogButtonAction } from '@/store/dialog';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteMessageDialog',
  props: {
		event: {
			type: Object,
			required: true,
		},
		room: {
			type: Room,
			required: true,
		},
	},
  emits: ['yes', 'close'],
  setup(__props, { emit: __emit }) {

	const emit = __emit;

	const props = __props;

	async function close(returnValue: DialogButtonAction) {
		if (returnValue === 1) {
			emit('yes');
		}
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  const _component_RoomEvent = _resolveComponent("RoomEvent")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "text-black",
    title: _ctx.$t('message.delete.heading'),
    buttons: _unref(buttonsYesNo),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (close($event))),
    width: "max-w-full lg:max-w-[40%] min-w-[92.5%] lg:min-w-[22.5%]"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('message.delete.cannot_undo')), 1),
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_RoomEvent, {
            class: "w-fit",
            event: props.event,
            room: props.room,
            deleteMessageDialog: true
          }, null, 8, ["event", "room"])
        ]),
        _: 1
      }))
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})
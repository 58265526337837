import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, Suspense as _Suspense } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-wrap items-center" }
const _hoisted_3 = { class: "relative flex flex-wrap items-center w-full gap-x-2 md:w-fit pr-2 min-h-6" }
const _hoisted_4 = { class: "flex gap-2 flex-wrap" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "bg-hub-background-3 flex px-2 gap-3 items-center rounded-md" }

import { usePubHubs } from '@/core/pubhubsStore';
	import { router } from '@/core/router';
	import { TMessageEvent } from '@/model/events/TMessageEvent';
	import Room from '@/model/rooms/Room';
	import { useConnection } from '@/store/connection';
	import { useMessageActions } from '@/store/message-actions';
	import { PluginType } from '@/store/plugins';
	import { RoomType } from '@/store/rooms';
	import { FeatureFlag, useSettings } from '@/store/settings';
	import { useUser } from '@/store/user';
	import { computed, ref } from 'vue';
	import MessageSnippet from './MessageSnippet.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomEvent',
  props: {
    event: {},
    room: {},
    deleteMessageDialog: { type: Boolean, default: false }
  },
  emits: ["inReplyToClick", "deleteMessage"],
  setup(__props: any, { emit: __emit }) {

	const connection = useConnection();
	const messageActions = useMessageActions();

	const user = useUser();

	const settings = useSettings();

	const props = __props;

	const inReplyToId = props.event.content['m.relates_to']?.['m.in_reply_to']?.event_id;

	const emit = __emit;

	const msgIsNotSend = computed(() => {
		return props.event.event_id.substring(0, 1) === '~';
	});

	const redactedMessage = computed(() => {
		const inRedactedMessageIds = props.room.inRedactedMessageIds(props.event.event_id);
		const containsRedactedBecause = props.event.unsigned?.redacted_because != undefined;
		// Remove the event id from the list with redacted event IDs if the event already contains the redacted_because key
		if (inRedactedMessageIds && containsRedactedBecause) {
			props.room.removeRedactedEventId(props.event.event_id);
		}
		return inRedactedMessageIds || containsRedactedBecause;
	});

	function onInReplyToClick() {
		if (!inReplyToId) return;
		emit('inReplyToClick', inReplyToId);
	}

	function onDeleteMessage(event: TMessageEvent) {
		emit('deleteMessage', event);
	}

	function reply() {
		messageActions.replyingTo = undefined;
		messageActions.replyingTo = props.event.event_id;
	}

	function resend() {
		const pubhubs = usePubHubs();
		pubhubs.resendEvent(props.event);
	}

	// Waits for checking if message is realy send. Otherwise a 'resend' button appears. See also msgIsNotSend computed.
	const timerReady = ref(false);
	window.setTimeout(() => {
		timerReady.value = true;
	}, 1000);

return (_ctx: any,_cache: any) => {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_UserDisplayName = _resolveComponent("UserDisplayName")!
  const _component_EventTime = _resolveComponent("EventTime")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RoomEventActionsPopup = _resolveComponent("RoomEventActionsPopup")!
  const _component_ProfileAttributes = _resolveComponent("ProfileAttributes")!
  const _component_Message = _resolveComponent("Message")!
  const _component_MessageSigned = _resolveComponent("MessageSigned")!
  const _component_MessageFile = _resolveComponent("MessageFile")!
  const _component_MessageImage = _resolveComponent("MessageImage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.event.plugin && _ctx.event.plugin.plugintype === _unref(PluginType).EVENT && _ctx.event.type === _ctx.event.plugin.type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.event.plugin.component), { event: _ctx.event }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.event.plugin.component), 1)
            ]),
            _: 1
          }, 8, ["event"]))
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["group flex gap-4 pl-6 pr-3 py-4", { 'transition-all duration-150 ease-in-out hover:bg-lightgray-light hover:dark:bg-hub-background-2': !_ctx.deleteMessageDialog, 'mx-4 shadow-[0_0_5px_0_rgba(0,0,0,0.3)] rounded': props.deleteMessageDialog }])
        }, [
          _createVNode(_component_Avatar, {
            userId: _ctx.event.sender,
            room: _ctx.room
          }, null, 8, ["userId", "room"]),
          _createElementVNode("div", {
            class: _normalizeClass({ 'w-5/6': _ctx.deleteMessageDialog, 'w-4/5 xl:w-3/5': !_ctx.deleteMessageDialog })
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_UserDisplayName, {
                  user: _ctx.event.sender,
                  room: _ctx.room
                }, null, 8, ["user", "room"]),
                _createElementVNode("div", _hoisted_4, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs font-normal" }, "|", -1)),
                  _createVNode(_component_EventTime, {
                    timestamp: _ctx.event.origin_server_ts,
                    showDate: false
                  }, null, 8, ["timestamp"]),
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-xs font-normal" }, "|", -1)),
                  _createVNode(_component_EventTime, {
                    timestamp: _ctx.event.origin_server_ts,
                    showDate: true
                  }, null, 8, ["timestamp"])
                ]),
                (timerReady.value && !_ctx.deleteMessageDialog)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (msgIsNotSend.value && _unref(connection).isOn)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (resend())),
                            class: "ml-2 mb-1",
                            title: _ctx.$t('errors.resend')
                          }, [
                            _createVNode(_component_Icon, {
                              type: "refresh",
                              size: "sm",
                              class: "text-red"
                            })
                          ], 8, _hoisted_5))
                        : _createCommentVNode("", true),
                      (msgIsNotSend.value && !_unref(connection).isOn)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 1,
                            type: "lost-connection",
                            size: "sm",
                            class: "ml-2 mb-1 text-red"
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (!_ctx.deleteMessageDialog)
                  ? (_openBlock(), _createBlock(_component_RoomEventActionsPopup, { key: 1 }, {
                      default: _withCtx(() => [
                        (!msgIsNotSend.value && !redactedMessage.value)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              onClick: reply,
                              class: "p-1 bg-gray-lighter hover:bg-gray-light dark:bg-gray-middle hover:dark:bg-gray-darker rounded-md"
                            }, [
                              _createVNode(_component_Icon, {
                                type: 'reply',
                                size: 'xs'
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (!msgIsNotSend.value && _unref(user).isAdmin && _ctx.event.sender !== _unref(user).user.userId)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ name: 'ask-disclosure', query: { user: _ctx.event.sender } }))),
                              class: "flex p-1 bg-gray-lighter hover:bg-gray-light dark:bg-gray-middle hover:dark:bg-gray-darker rounded-md",
                              title: _ctx.$t('menu.moderation_tools_disclosure')
                            }, [
                              _createVNode(_component_Icon, {
                                type: 'warning',
                                size: 'xs'
                              })
                            ], 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        (_unref(settings).isFeatureEnabled(_unref(FeatureFlag).deleteMessages) && !msgIsNotSend.value && _ctx.event.sender === _unref(user).user.userId && !redactedMessage.value)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (onDeleteMessage(_ctx.event))),
                              class: "p-1 bg-gray-lighter dark:bg-gray-middle hover:bg-red hover:text-white dark:hover:bg-red dark:hover:text-white rounded-md",
                              title: _ctx.$t('menu.delete_message')
                            }, [
                              _createVNode(_component_Icon, {
                                type: 'bin',
                                size: 'xs'
                              })
                            ], 8, _hoisted_7))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (props.room.getType() == _unref(RoomType).PH_MESSAGES_RESTRICTED)
                  ? (_openBlock(), _createBlock(_component_ProfileAttributes, {
                      key: 2,
                      user: _ctx.event.sender,
                      room_id: _ctx.event.room_id
                    }, null, 8, ["user", "room_id"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.event.plugin?.plugintype === _unref(PluginType).MESSAGE && _ctx.event.content.msgtype === _ctx.event.plugin.type)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.event.plugin.component), {
                  key: 0,
                  event: _ctx.event
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.event.plugin.component), 1)
                  ]),
                  _: 1
                }, 8, ["event"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    fallback: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
                      ])
                    ]),
                    default: _withCtx(() => [
                      (_unref(inReplyToId) && !redactedMessage.value)
                        ? (_openBlock(), _createBlock(MessageSnippet, {
                            key: 0,
                            class: _normalizeClass({ '!bg-[#e2e2e2]': _ctx.deleteMessageDialog }),
                            onClick: onInReplyToClick,
                            eventId: _unref(inReplyToId),
                            showInReplyTo: true,
                            room: _ctx.room
                          }, null, 8, ["class", "eventId", "room"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })),
                  (_ctx.event.content.msgtype === 'm.text' || redactedMessage.value)
                    ? (_openBlock(), _createBlock(_component_Message, {
                        key: 0,
                        event: _ctx.event,
                        deleted: redactedMessage.value
                      }, null, 8, ["event", "deleted"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'pubhubs.signed_message' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(_component_MessageSigned, {
                        key: 1,
                        class: _normalizeClass({ '!bg-[#e2e2e2]': _ctx.deleteMessageDialog }),
                        message: _ctx.event.content.signed_message
                      }, null, 8, ["class", "message"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'm.file' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(_component_MessageFile, {
                        key: 2,
                        message: _ctx.event.content
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'm.image' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(_component_MessageImage, {
                        key: 3,
                        message: _ctx.event.content,
                        class: "w-[20rem] max-h-[25rem]"
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true)
                ], 64))
          ], 2)
        ], 2))
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue';
	import { useUserColor } from '@/composables/useUserColor';
	import Room from '@/model/rooms/Room';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	interface Props {
		userId: string;
		// img is used when avatar is uploaded or removed.
		img?: string;
		// To fetch the latest avatar when in room for each member.
		room?: Room;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Avatar',
  props: {
    userId: {},
    img: { default: '' },
    room: { default: undefined }
  },
  setup(__props: any) {

	const downloadUrl = useMatrixFiles();
	const { color, bgColor } = useUserColor();

	const props = __props;

	const image = computed(() => {
		if (props.room) {
			const memberAvatarMxcUrl = props.room.getMember(props.userId)?.getMxcAvatarUrl();
			return memberAvatarMxcUrl ? downloadUrl.formUrlfromMxc(memberAvatarMxcUrl) : '';
		}

		// When there is props.img passed, then use the props.img.
		return props.img === '' ? '' : downloadUrl.formUrlfromMxc(props.img);
	});

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-full w-12 h-full aspect-square flex items-center justify-center overflow-hidden", _unref(bgColor)(_unref(color)(_ctx.userId))])
  }, [
    (image.value !== '')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          "data-testid": "avatar",
          src: image.value,
          class: "rounded-full w-full h-full"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          type: "person",
          class: "right-0 group-hover:block h-16 w-16"
        }))
  ], 2))
}
}

})